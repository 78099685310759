.root {
  @apply p-0 left-0 top-0 absolute !important; /* Multislider root padding is stubborn */
}

.slider {
  @apply text-gray-800;
}

.track {
  @apply h-0.5 text-gray-800 py-0;
}

.thumb:before {
  @apply rounded-full;
}
