.arrow,
.arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 0.125rem;
  background: inherit;
}

.arrow {
  visibility: hidden;
  top: -6px;
}

.arrow::before {
  left: 0;
  content: '';
  transform: rotate(45deg);
  visibility: visible;
}
