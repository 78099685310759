.holder {
  @apply overflow-hidden;
}

.billDate[data-variant='large'] .holder {
  @apply flex border rounded-lg lg:border-l-0 lg:border-r-0 lg:rounded-none;
}

.billDate[data-variant='medium'] .holder {
  @apply flex border rounded-lg;
}

.billDate[data-variant='small'] .holder {
  @apply autoType400 bg-gray-100 rounded-md text-gray-900 flex;
}
