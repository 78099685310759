@media (min-height: 372px) {
    .searchFilterWrapper > div > div:first-child > div {
      max-height: 200px;
      overflow: auto;
    }
  }
  
  @media (min-height: 650px) {
    .searchFilterWrapper > div > div:first-child > div {
      max-height: 350px;
      overflow: auto;
    }
  }
  
  @media (min-height: 768px) {
    .searchFilterWrapper > div > div:first-child > div {
      max-height: 500px;
      overflow: auto;
    }
  }
  
  @media (min-height: 992px) {
    .searchFilterWrapper > div > div:first-child > div {
      max-height: fit-content;
    }
  }
  