.dateButton[data-is-selected='true'] {
  @apply bg-gray-200;
}

.date[data-is-unavailable='true'] {
  @apply text-gray-500 line-through;
}

.dateButton[data-variant='large'] {
  @apply w-1/2 py-4 pl-8 text-left;
}

.dateButton[data-variant='large'] .date {
  @apply mt-1 text-gray-900 highlight autoType600;
}

.dateButton[data-variant='medium'] {
  @apply w-1/2 py-4 pl-8 text-left;
}

.dateButton[data-variant='medium'] .date {
  @apply mt-1 text-gray-900 highlight autoType600;
}

.dateButton[data-variant='small'] {
  @apply px-3 py-3 highlight autoType400 xl:px-6;
}

.dateButton[data-variant='small']:first-of-type {
  @apply rounded-l-md;
}

.dateButton[data-variant='small']:last-of-type {
  @apply rounded-r-md;
}

.dateButton[data-variant='small'] .label {
  @apply sr-only;
}
