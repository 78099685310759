.label[data-disabled='true'] {
  @apply opacity-50 cursor-default;
}

.input {
  width: 1.25rem;
  height: 1.25rem;
}

.control {
  width: 1.25rem;
  height: 1.25rem;
}

.control::before {
  @apply rounded-full transition-transform duration-200;
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  box-shadow: inset 0.5rem 0.5rem currentColor;
  transform: scale(0);
}

.input:checked + .control::before {
  transform: scale(1);
  @apply text-green-500;
}

.input:hover:not(:disabled) + .control {
  @apply border-primary-800 text-gray-800;
}

.input:checked + .control {
  @apply border-green-500 text-primary-900;
}

.label,
.input {
  margin-top: 1px;
}

.input:focus-visible + .control::after {
  @apply block -inset-6 absolute border-primary-800 border-2 rounded-full;
  content: '';
}

.outline {
  @apply border-solid border-2 rounded-box border-gray-310 p-3 cursor-pointer text autoType300 relative;
}

.active.outline {
  @apply border-green-500;
}
