.container {
  animation: animateIn 400ms ease-in-out forwards;
  z-index: 2000;
}

@keyframes animateIn {
  from {
    transform: translateY(-100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.input {
  @apply w-full py-1 text-gray-900 outline-none autoType600 highlight;
}
.input::placeholder {
  @apply text-gray-300 font-normal text-base semiHighlight;
}
