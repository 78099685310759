.container {
  @apply relative px-4 py-3 border border-gray-200 rounded-lg autoType300;
}

/* hover */
.container:hover {
  @apply border-gray-800;
}
.container[data-error='false'][data-empty='false']:hover .label {
  @apply text-gray-800;
}

/* focus */
.container[data-focused='true'] {
  @apply border-gray-800;
}
.container[data-error='false'][data-focused='true'] .label {
  @apply text-gray-800;
}

/* error  */
.container[data-error='true'] {
  @apply border-red-800;
}
.container[data-error='true'] .label,
.container[data-error='true'] .input,
.container[data-error='true'] .input:-webkit-autofill::first-line {
  @apply text-red-800;
}
.error {
  @apply mt-1 text-red-800 text autoType200;
}

/* label behaviour */
.label {
  @apply absolute z-20 px-1 text-gray-800 duration-100 ease-linear transform pointer-events-none;
  background-color: inherit;
  max-width: calc(100% - 2rem);
  transition-property: transform, font-size, color;
}

.label:before {
  content: '';
  @apply absolute top-0 left-0 w-full px-1 bg-white;
  bottom: 6px;
  height: 100%;
  z-index: -1;
}

.container[data-focused='true'] .label,
.container[data-empty='false'] .label {
  @apply -translate-y-6 autoType200;
}

/*
 * Since we're targetting a vendor specific pseudo class we need to keep it
 * separate so it doesn't invalidate this declaration for the other selectors.
 */
.container .input:-webkit-autofill + .label {
  @apply -translate-y-6 autoType200;
}
