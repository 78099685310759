.container:hover,
.input:focus + .container,
.input:checked + .container {
  @apply border-green-500;
}

.container:hover + .label,
.input:focus + .container > .label,
.input:checked + .container > .label {
  @apply text-gray-800;
}

.container:hover + label {
  @apply underline;
}

.input:disabled + .container {
  @apply bg-gray-100 pointer-events-none;
}

.input:checked + .container > .icon {
  display: block;
  @apply text-green-500;
}

.container {
  @apply border-neutral-20;
}
