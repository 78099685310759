.deductible_table {
  @apply w-full text-sm md:text-sm text-gray-900 highlight mt-6 h-px;
}
.deductible_table th {
  @apply align-bottom;
}
.deductible_table tr[data-closed='true'] {
  @apply border-b border-gray-200;
}
.deductible_table tr[data-closed='true'] th,
.deductible_table tr[data-closed='true'] td {
  @apply pb-2;
}
.deductible_table tr[data-row='true'] th,
.deductible_table tr[data-row='true'] td {
  @apply pt-2;
}
.deductible_table tr td:first-child,
.deductible_table tr th:first-child {
  @apply text-left font-normal md:w-48;
}
.deductible_table tr td:not(:first-child),
.deductible_table tr th:not(:first-child) {
  @apply text-center font-normal md:w-auto;
}

@media (max-width: 768px) {
  .deductible_table[data-multiple-options='true'] tr td:not(:first-child),
  .deductible_table[data-multiple-options='true'] tr th:not(:first-child) {
    width: 19%;
  }
}
.radio {
  @apply w-full h-full text-left;
}
.radio_label {
  padding: 1rem !important;
}

.cell_wrapper {
  max-width: 150px;
}

.icon {
  @apply mr-12 md:mr-16;
}

.icon.isChecked > path {
  @apply fill-green-500;
}
