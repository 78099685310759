.button {
  @apply pl-0 pr-0 py-2 !important;
  @apply w-full font-normal;
}

.button:disabled,
.button[data-state='disabled'] {
  @apply font-medium;
}

.required {
  @apply pl-1 font-thin text-base;
}
