.checkbox {
  @apply block mr-2.5 md:mr-4 border-2 border-gray-310 border-solid cursor-pointer;
  width: 1.25em; /* Using `em` to keep it relative to the `<label>` font-size */
  min-width: 1.25em;
  height: 1.25em;
}

.checkbox[data-selected='true'] {
  @apply border-green-500 text-green-500;
}

.checkbox[data-checked='true'] .icon {
  @apply opacity-100;
}

.checkboxComponent > span {
  @apply border-gray-310;
}
